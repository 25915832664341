import EnrollForm from "/src/components/enrollForm"
import Layout from "/src/components/layout"
import "/src/styles/yogaPage.css"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

function YogaPage() {
  return (
    <>
      <Layout route="/courses">
        <div id="yogaPage">
          <div className="grid grid-rows-1 hero">
            <div>
              {" "}
              <h1>YOGA CIRCLE</h1>
            </div>
          </div>
          <div className="grid grid-rows-1 genInfoRow">
            <div className="grid grid-cols-12 ">
              <div className="col-start-2 col-span-10 md:col-start-2 md:ml-8 md:col-span-4 genInfo ">
                GENERAL
                <br className="hidden md:block" /> INFO
              </div>
              <div className="col-start-2 col-span-10 md:col-span-6 md:col-start-6 genText ">
                <p>
                  Join our group yoga sessions and kick start the day with
                  positive vibes.
                </p>
                <p>
                  Timing :{" "}
                  <span className="num">
                    {" "}
                    6:00 am to 7:00 am, 7:00 pm to 8:00 pm
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="grid grid-rows-1 w-full instructorRow">
            <div className="grid grid-cols-12 w-full ">
              <div className="col-start-2 col-span-10 md:col-span-2 md:col-start-6 mb-7 insHeadMob block lg:hidden">
                MEET YOUR TRAINERS
              </div>

              {/* Modifications  */}

              <div className="col-start-2 col-span-10 md:col-span-10 md:col-start-2 ">
                <div className="grid grid-rows-1 ">
                  <div className=" grid grid-cols-12">
                    <div className="col-start-2 col-span-10 md:col-span-10 md:col-start-1 insHead hidden lg:block mb-9 ml-8 ">
                      MEET YOUR TRAINERS
                    </div>
                    {/* Instructor1  */}
                    <div className="col-start-2 col-span-10 md:col-start-1 md:col-span-3 md:ml-8 insImg insText">
                      {/* <img src={Instructor} alt="instructor" /> */}

                      <StaticImage
                        src="../../images/meetInstructor.jpg"
                        quality={100}
                        placeholder="blurred"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="instructor"
                        className="inset-0"
                      />
                      <p>
                        <b>Dr Anjana Pattali</b>
                        <br />
                        BAMS, Msc Yoga Therapy, <br />
                        Director , Antaryami
                        <br />
                      </p>
                    </div>
                    {/* Instructor2  */}
                    <div className="col-start-2 col-span-10 md:col-start-4 md:col-span-3 md:ml-8 insImg insText">
                      {/* <img src={Instructor1} alt="instructor" /> */}
                      <StaticImage
                        src="../../images/sruthi.jpg"
                        quality={100}
                        placeholder="blurred"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="instructor"
                        className="inset-0"
                      />
                      <p>
                        <b>Sruthi A</b>
                        <br />
                        Msc Yoga Therapy, <br />
                        <br />
                      </p>
                    </div>
                    {/* Instructor3  */}
                    <div className="col-start-2 col-span-10 md:col-start-7 md:ml-8 md:col-span-3 insImg insText">
                      {/* <img src={Instructor2} alt="instructor" /> */}
                      <StaticImage
                        src="../../images/shivapriya.jpg"
                        quality={100}
                        placeholder="blurred"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="instructor"
                        className="inset-0"
                      />
                      <p>
                        <b>Shivapriya K</b>
                        <br />
                        Msc Yoga Therapy, <br />
                        <br />
                      </p>
                    </div>
                    {/* Instructor4  */}
                    <div className="col-start-2 col-span-10 md:col-start-10 md:ml-8 md:col-span-3 insImg insText">
                      {/* <img src={Instructor3} alt="instructor" /> */}
                      <StaticImage
                        src="../../images/akhila.jpeg"
                        quality={100}
                        placeholder="blurred"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="instructor"
                        className="inset-0"
                      />
                      <p>
                        <b>Akhila C K</b>
                        <br />
                        Msc Yoga Therapy, <br />
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* -----------------------  */}
            </div>
          </div>
          <EnrollForm category="Yoga Circle Enrollment" />
        </div>
      </Layout>
    </>
  )
}

export default YogaPage
